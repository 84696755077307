.wait{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
	background-color: rgba(0, 0, 0, 0);
	-webkit-transition:opacity .15s linear;
	transition:opacity .15s linear
}
.wait.open{
	background-color: rgba(0, 0, 0, 0.5);
}
.wait div{
	position: fixed;
	top: 30%;
	width: 100%;
	padding: 8px;
}
.wait div span{
	display: block;
	text-align: center;
	color: black;
	width: 150px;
	background: white;
}

.alert-dismissable .close{
	top: -30px !important;
}

.adm_titre_H3
{
	text-align: center;
	color: #28ACFF;
	font-weight: bold;
}
.adm_titre_discret
{
	text-align: center;
	color: grey;
	font-size: small;
}

.adm_titre_H5
{
	text-align: left;
	color: #045FB4;
	font-weight: bold;
}

.adm_recherche_titre
{
	font-size: 1em;
}

.adm_new_valeur
{
	color : #C40000;
	font-weight: bold;
}

.adm_new_correpondant_info
{
	font-weight: bold;
	color: red;
}

.adm_alert
{
	background-color: #ffcccc;
}

.detailVisu_asso_table
{
	border:5px;
	border-color:#F72907;
}
.red {
	color: red
}

#cocherCase,#decocherCase
{
	cursor: pointer;
	color: #28ACFF;
}

.adm_th_resultat
{
	text-align:center;
	background : #cecece;
	text : bold;
}

.adm_td_resultat
{
	text-align:center;
}

.sadm_header
{
	background : #cecece;
}

.adm_titre_tableau_text
{
	color:#428BCA;
	font-weight : bold;
}

.menu_gauche_li
{
	margin-left: 25px;
}

/***********CORRESPOND Aux Glyphicons *************/
@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?-jmk3ls');
	src:url('fonts/icomoon.eot?#iefix-jmk3ls') format('embedded-opentype'),
	url('fonts/icomoon.woff?-jmk3ls') format('woff'),
	url('fonts/icomoon.ttf?-jmk3ls') format('truetype'),
	url('fonts/icomoon.svg?-jmk3ls#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.icon-cross:after {
	content: "\ea0f";
}
.icon-cross:hover:after {
	color : #666666;
}
.icon-cross:active {
	box-shadow: inset 0px 2px 2px 2px #C5C5C5 ;
}

.icon-home:after {
	content: "\e900";
}
.icon-home:hover:after {
	color : white;
}

.icon-user:after {
	content: "\e971";
}
.icon-user:hover:after {
	color : white;
}

.icon-lock:after {
	content: "\e98f";
}
.icon-lock:hover:after {
	color : white;
}

.icon-stats-dots:after {
	content: "\e99b";
}
.icon-stats-dots:hover:after {
	color : white;
}

.icon-switch:after {
	content: "\e9b6";
}
.icon-switch:hover:after {
	color : white;
}

.icon-plus:after {
	content: "\ea0a";
}
.icon-plus:hover:after {
	color : #666666;
}
.icon-plus:active {
	box-shadow: inset 0px 2px 2px 2px #C5C5C5 ;
}

#compteur_table{
	display: none;
	right: 18px;
	position: fixed;
	z-index: 1001;
	background: #fff;
	box-shadow: -1px 3px 9px 4px #c5c5c5;
	border-radius: 4px;
}
#compteur_table span:first-child{
	background-color: rgba(66, 139, 202, 1);
	color: #fff;
	text-align: center;
	display: block;
	font-size: 18px;
	padding: 4px 4px 0px 0px;
	border-radius: 4px 4px 0px 0px;
}
#compteur_table span:last-child{
	padding: 10px 10px 10px 10px;
	display: block;
}
#compteur_table table{
	font-weight: bold;
}
#compteur_table.open{
	/*display: block;*/
}
/*TODO inclure ce fichier dans le html + le finir au niveau du style */

.sans-asterisque span.required{
	display: none !important;
}


.aide {
	width: 15px;
	height: 15px;
	background: url("/build/images/picto-aide.png") no-repeat;
	background-size: cover;
	vertical-align: middle;
	margin-bottom: 2px;
}

/* Tooltip */
.tooltips {
	position: relative;
	display: inline-block;
}

.tooltips .tooltiptext {
	visibility: hidden;
	width: 200px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	/*margin-left: -60px;*/
	margin-left: -100px;
	margin-bottom: 5px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 12px;
	line-height: 14px;
	font-weight: bold;
}

.tooltips .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltips:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltip-title {
	display: inline-flex;
	width: 100%;
}

.tooltip-title .tooltips {
	margin-left: 0;
	margin-right: auto;
	margin-bottom: 15px;
	margin-top: auto;
}

.tooltip-title h3 {
	margin-left: auto;
	margin-right: 0;
}

.center {
	text-align: center;
	vertical-align: center;
}

.mailjet-table, .mailjet-table > tbody > tr > td {
	border: 1px solid rgb(229, 229, 229);
}

.mailjet-table > tbody > tr > td {
	padding: 10px;
	color: rgb(85, 85, 85);
	font-weight: 400;
	line-height: 20px;
}

.mailjet-table {
	margin-right: auto;
	margin-left: auto;
}

.email-tab td:nth-child(3){
	width: 200px;
}